/* Quick Exit button */
.qe-vietnamese {
  padding: 15px 8px!important;
  font-size: 0.8rem;
}

.bigger-btn-vietnamese {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: inline;
}

.vietnamese-badges > span {
  line-height: 1rem;
  padding: 0.375rem 1rem !important;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) { }

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {
  .bigger-btn-vietnamese {
      padding-top: 0.75rem!important;
      padding-bottom: 0.75rem!important;
      display: inline-block;
  }
}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
