.dot-pulse-wrapper-div {
  position: relative;
  right: 28px;
  top: 0;
  width: 0;
  height: 16px;
}

.extra-thin-button .dot-pulse-wrapper-div {
  right: 10px;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	   */
@media (min-width: 640px) { }

/* md	- starts at width 768px	   */
@media (min-width: 768px) { }

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px */
@media (min-width: 1536px) { }
