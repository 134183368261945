#interview-background {
  height: 100vh;
  height: 100dvh;
  min-width: 100%;
  background-image: linear-gradient(#f0fdfa, white 2rem, white 80%, #ccfbf1);
  /* background-color: #ccfbf1; */
  background-color: #ffe8d3;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: fixed;
  top: 78px;
  left: 0;
  /* filter: blur(8px); */
  z-index: -2;
  opacity: 0.95;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  #interview-background {
    height: 100vh;
    height: 100dvh;
    min-width: 100%;
    /* background-image: url('./pages/app-background.webp'); */
    background-image: none;
    /* background-color: #fbf2fd; */
    background-color: #ffe8d3;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    /* filter: blur(8px); */
    z-index: -2;
    opacity: 0.85;
  }

  #interview-background {
    top: 102px;
  }
}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
