.quick-exit {
  background-color: #FFE033!important;
  font-weight: 500;
  position: fixed;
  bottom: 24px;
  right: 16px;
  border-radius: 50%!important;
  padding: 15px 12px!important;
  font-size: 0.85rem;
  z-index: 100!important;
}

.quick-exit span {
  display: none;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {  }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  .quick-exit {
    position: absolute;
    top: 102px;
    bottom: auto;
    right: 0;
    font-size: 1rem;
    border-radius: 6px!important;
  }

  .quick-exit:hover {
    background-color: #ffd800!important;
  }

  .quick-exit span {
    display: inline;
  }

}


/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {
}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) {
  .quick-exit {
    background-color: #FFE033!important;
    position: relative;
    top: 0;
    right: 0;
    box-shadow: none!important;
    /* font-size: 1rem!important; */
  }
}

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
