#image-section-wrapper {
  display: none;
}

#image-section {
  margin: 1rem 1rem 0 1rem;
  height: 100%;
  min-height: 260px;
  width: calc(100% - 2.0rem) !important;
  background-image: url("https://legaltechhelper.com.au/assets/customers/avwa/img/splash.svg");
  background-color: #ffffff;
  background-position: 41% center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.375rem;
}

.with-extra-border {
  border-right: 1px solid #e5dbdb;
}

/* lg	- starts at width 1024px	 */

/* Large screen but not enough height for two panels - landscape mode*/
@media (min-width: 1024px) and (max-height: 768px) and (orientation:landscape) {
  #image-section-wrapper {
      display: flex;
      width: 65%;
      height: calc(100vh - 104px);
  }

  #image-section-wrapper  .heroSection {
    background-color: transparent;
    width: 100%!important;
    margin: 0;
  }

  #text-section .back-link {
    width: auto;
    margin: 1rem 2rem!important;
  }

  #text-section #interview {
    /* width: 60vw!important; */
    width: 100%!important;
    background-color: #fff;
  }

  #text-section .da-image {
    display: none;
  }

  #text-section #interview-outer-container {
    margin: 0 auto;
    width: auto;
    box-shadow: none;
  }

}

/* Large screen and enough height for two panels - landscape mode*/
@media (min-width: 1024px) and (min-height: 769px) and (orientation:landscape) {
    #image-section-wrapper {
      display: none;
    }

    #text-section #interview {
      width: 100vw;
    }

    #text-section .da-image {
      display: block;
    }

    #text-section #interview-outer-container {
      margin: 0 auto 3.75rem auto;
    }
}
