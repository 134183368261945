#interview {
  min-height: calc(100vh - 104px)!important;
  min-height: calc(100dvh - 104px)!important;
  width: 100vw;
  max-width: 100%;
  display: flex;
}

.main-text-frame div p {
  margin-top: 1rem;
}

.main-text-frame div ul ul,
.main-text-frame div ul ol {
  padding-top: 1rem!important;
  list-style: none;
}

.main-text-frame div ul {
  color: rgb(43,62,233);
  list-style: square;
  margin-left: 1rem;
}

.main-text-frame div ul li span {
  color: #000;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.main-text-frame div ol li {
  padding-top: 0.25rem;
    margin-left: 1rem;
    padding-bottom: 0.25rem;
}

a.regularLink {
    color: #2B3EE9;
    padding-bottom: 1px;
    border-bottom: 2px solid #2B3EE9;
    font-weight: 500;
}

.static-conent {
  line-height: 1.75;
  background-color: white;
  margin-bottom: 3.5rem;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {
  .static-conent {
    background-color: transparent;
    margin-bottom: 0;
  }
}

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  #interview {
    background-image: none;
    background-color: transparent;
  }
}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
