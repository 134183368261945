/* Spinner */
.spinner-container {
  min-height: calc(100vh - 104px);
  min-height: calc(100dvh - 104px);
  background-color: white;
}

.spinner-wrapper {
  width: 100%;
  height: 100vh;
  text-align: center;
  padding-top: calc(50vh - 60px);
  padding-top: calc(50dvh - 60px);
}

.spinner,
.spinner div {
  box-sizing: border-box;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner div {
  position: absolute;
  border: none;
  opacity: 0.8;
  border-radius: 50%;
  animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  background-image: radial-gradient(#f5ffb6, #ffda99, #ffb2a5, #ff98cc, #c395f5);
  background-image: radial-gradient(#B24FB0 70%, #FFFFFF 80%, #5F29A8 100%);
  background-image: radial-gradient(#dc76ed 70%, #FFFFFF 80%, #e11ed1 100%);
  color: #C395F5;
}

.spinner div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes spinner {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
