nav {
  background-color: #ffffff;
}

#interview-outer-container {
  position: absolute;
  left: 0;
  top: 80px;
  margin-top: 0;
  padding-top: 2rem;
  padding-bottom: 1rem;
  /* background-image: linear-gradient(#f0fdfa, white 2rem, white 80%, #ccfbf1);
  min-height: calc(100vh - 108px);
  min-height: calc(100dvh - 108px); */
}

#cards-outer-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 100%;
}

#cards-outer-container > #interview-outer-container {
  /* top: 104px; */
  margin-top: 0;
  padding-top: 2rem;
}

#cards-outer-container > #interview-outer-container > h1 {
  margin-top: 0.25rem;
  margin-bottom: 0;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {

  #interview-outer-container {
    position: relative;
    left: auto;
    top: auto;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    margin-bottom: 3.75rem;
  }

  .simple-page {
    margin-top: 3rem!important;
  }

  .simple-page #interview-outer-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
  }

  #cards-outer-container {
    position: relative;
    left: auto;
    top: auto;
    width: 100%;
    max-width: 100%;
  }

  #cards-outer-container > #interview-outer-container {
    top: auto;
    /* padding-top: 1rem;
    padding-bottom: 1.5rem; */
  }

  /* #cards-outer-container > #interview-outer-container > div {
    margin-top: -0.75rem;
  } */

}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {

  /* #cards-outer-container > #interview-outer-container > div {
    margin-top: -0.25rem;
  } */

}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
