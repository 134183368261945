/* .with-main-menu {
  margin-left: -2rem;
  margin-right: -2rem;
} */

/* Gradient buttons */
/* .gradient-button.primary {
  background-image: linear-gradient(to right, #2B3EE9, #6B21A8);
}

.gradient-button.primary:hover {
  background-image: linear-gradient(to right, #101E9E, #44337A);
}

.gradient-button.success {
    background-image: linear-gradient(to right, #11583C 20%, #29871C);
}

.gradient-button.success:hover {
    background-image: linear-gradient(to right, #0B3626 20%, #1D6616);
}

.gradient-button.warning {
    background-image: linear-gradient(to right, #E57700 20%, #BB5429);
}

.gradient-button.warning:hover {
    background-image: linear-gradient(to right, #C46D02 20%, #994A23);
} */

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  .with-main-menu {
    margin-left: 0!important;
    margin-right: 0!important;
  }
}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
