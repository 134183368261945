#interview-outer-container ..radio-panel label {
  border: 1px solid #e2e8f0;
  font-weight: 500;
}

#interview-outer-container .radio-panel input[type=radio] {
  width: 2rem;
  vertical-align: middle;
}

#interview-outer-container .radio-panel label > input[type=radio] {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  max-height: 2rem;
}

#interview-outer-container .radio-panel input[type='radio']:after {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  /* top: -2px; */
  top: calc(50% - 16px);
  left: 0px;
  position: relative;
  background-color: #94a3b8;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}

.radio-label {
  width: calc(100vw - 7rem);
}

#interview-outer-container .radio-panel input[type='radio']:checked:after {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  /* top: -2px; */
  top: calc(50% - 16px);
  left: 0px;
  position: relative;
  background-color: #2B3EE9;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'/%3E%3C/svg%3E");
  content: url('data:image/svg+xml,<svg fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m8.3942 12 2.4038 2.3509 4.8077-4.7016" stroke-width="2"/></svg>');
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}

#interview-outer-container .radio-panel input[type=radio] {
  line-height: 1.75rem;
}

 /* Tailwindcss default responsive breakpoints*/
 /* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {

  .radio-label {
    width: calc(100% - 0.75rem);
    margin-left: 0.75rem!important;
  }
}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
