#splash-wrapper {
  background-color: #ffffff;
  min-height: calc(100vh - 104px);
  min-height: calc(100dvh - 104px);
}

.splash-text {
  position: absolute;
  bottom: 2rem;
  left: 33%;
  color: #ffffff;
  padding: 2rem 3rem;
  margin: auto 2rem auto auto;
  background: linear-gradient(159deg, #B24FB0 0%, #B24FB0 20%, #6B21A8 100%);
}

#splashPage ul {
  color: #7425b1;
  list-style-type: square;
  margin: 0.25rem 0 0 1.25rem;
  font-size: 1.15rem;
  line-height: 1.5rem;
}

#splashPage ul li span {
  color: #000000;
  font-size: 1rem;
}

#splashPage ul li:nth-child(2) {
  margin-bottom: 0.25rem;
}

#splashPage #tooltip-anchor-0 {
  border-bottom: 3px dotted #B24FB0;
}

.heroSection {
  margin: 1.75rem 1rem 0 1rem;
  height: 100%;
  min-height: 260px;
  width: calc(100% - 2.0rem) !important;
  background-image: url("https://legaltechhelper.com.au/assets/customers/avwa/img/splash.svg");
  background-color: #ffffff;
  background-position: 41% center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.375rem;
}

#second-panel>div>#app-button-0 {
  font-size: 1rem;
}

/**********************************************/
/* Tailwindcss default responsive breakpoints */
/**********************************************/

/* sm	- starts at width 640px	 */
@media (min-width: 640px) {
  .heroSection {
    margin: 1.5rem 0 0 1rem;
  }

  #splash-wrapper {
    background-color: transparent;
  }

  #second-panel {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem
  }
}

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  #splashPage ul {
    margin: 0.25rem 0 0 1.75rem;
  }
}

/* md screen but in portrait mode only */
@media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
    #splashPage {
      padding: 2rem;
      height: calc(100vh - 104px);
      height: calc(100dvh - 104px);
      justify-content: center;
    }

    .heroSection {
      background-position: center center;
      aspect-ratio: 21/9!important;
    }

    #first-panel {
      width: 100%!important;
    }

    #second-panel {
      width: 100%;
      position: relative;
      margin: 0;
      padding: 0;
      top: 0;
      bottom: unset;
    }
}

/* lg	- starts at width 1024px	 */

/* Large screen but not enough height for two panels - landscape mode*/
@media (min-width: 1024px) and (max-height: 768px) and (orientation:landscape) {
  #splashPage {
    height: calc(100vh - 104px);
    height: calc(100dvh - 104px);
    margin: auto 0;
    padding: 0;
    max-width: unset;
    margin-right: 1rem;
    flex-direction: row;
  }

  #first-panel {
    background-color: #ffe8d3;
    height: calc(100dvh - 104px);
  }

  .heroSection {
    /* position: relative;
    top: 0;
    left: 0; */
    /* width: calc(100% - 4rem) !important; */
    width: 100%!important;
    /* height: 50vh; */
    background-position: 50% center;
    background-size: contain;
    background-color: #ffe8d3;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-image: url("https://legaltechhelper.com.au/assets/customers/avwa/img/splash-16x9.svg");
    /* padding: 2rem; */
    /* margin: 0 2rem; */
  }

  #second-panel {
    padding-left: 1.75rem;
  }

  #second-panel > div > div > div,
  #second-panel > div > div > div > ul > li > span {
    font-size: 1.1rem!important;
  }

  #second-panel > div > button {
    font-size: 1rem!important;
  }

  .splash-app-button-wrapper {
    padding-top: 0.5rem 0.75rem;
  }

  #splash-app-button-0 {
    padding: 0.5rem 1rem;
    font-weight: 400;
    /* font-size: 1rem; */
  }

  /* #splashPage ul li span {
    color: #000000;
    font-size: 1.125rem;
    line-height: 1.75rem;
  } */
}

/* Large screen and enough height for two panels - landscape mode*/
@media (min-width: 1024px) and (min-height: 769px) and (orientation:landscape) {
  #splash-background {
    min-height: calc(100vh - 104px);
    min-height: calc(100dvh - 104px);
    min-width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
    left: 0;
    z-index: -2;
    opacity: 0.3;
    background: radial-gradient(circle at 50% 50%, #ff7e07, #ff8f30, #ff9f4c, #ffae66, #ffbd81, #ffcc9b, #ffdab7, #ffe8d3);
  }

  #first-panel {
    z-index: 0;
  }

  #second-panel {
    margin-bottom: 0;
  }

  #second-panel > div > div > div,
  #second-panel > div > div > div > ul > li > span,
  #second-panel > div > button {
    font-size: 1.1rem!important;
  }

  .heroSection {
    position: absolute;
    width: 55% !important;
    height: 22vw;
    min-width: 658px;
    min-height: 426px;
    top: 128px;
    left: 0;
    margin: 4rem 0 0 4.5rem;
    background-color: white;
    border-radius: 0.5rem;
    background-position: 60% center;
    box-shadow: 16px 16px 10px -4px rgba(255, 126, 7, 0.20);
  }

  #splashPage>div.w-full.lg\:w-3\/5 {
    position: absolute;
    bottom: 5.5rem;
    right: 5.5rem;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem 0 2rem;;
    box-shadow: 16px 16px 10px -4px rgba(255, 126, 7, 0.20);
    width: 55%;
  }

  #splashPage ul li span {
    color: #000000;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

/* xl	- starts at width 1280px	 */
/* @media (min-width: 1280px) {
    .heroSection {
      margin: 3.5rem 0 0 4rem;
    }

    #splashPage>div.w-full.lg\:w-3\/5 {
      position: absolute;
      bottom: 5rem;
      right: 4rem;
    }
} */

/* 2xl	- starts at width 1536px	 */
/* @media (min-width: 1536px) {} */
