#interview-outer-container > div > label > select {
  border: 2px solid #42279A;
  padding: 0.25rem 0.25rem;
  border-radius: 0.375rem;
}

.fill-in-the-blanks {
  line-height: 2.25rem;
}

.fill-in-the-blanks label {
    border: none!important;
    color: #42279A;
    font-weight: 600;
}

.fill-in-the-blanks label > select {
  background-color: white;
  height: 3rem;
  margin: 0.375rem 0.375rem 0.375rem 0;
  /* background-color: #F2FEFF; */
  background-color: #fcd5fa;
  color: #581C87;
  font-weight: 700;
  border: 2px solid #580C87;
}

.fill-in-the-blanks > label > select:invalid {
  color: #000; /* slate-800 */
}

.need-to-fill-in {
  /* border: 3px solid #c210bc!important; */
  border: 3px solid #E71B0E!important;
  /* box-shadow: 0px 0px 4px 4px rgba(194,16,188,0.35);
  -webkit-box-shadow: 0px 0px 4px 4px rgba(194,16,188,0.35); */
}

.fill-in-the-blanks button {
  line-height: 1.5rem;
}

.fitb-label {
  margin: 0 0.375rem 0 0;
  font-weight: 500;
  font-family: 'Bitter';
}

.fitb-note {
  color: #42279A;
  font-family: 'Bitter';
  font-weight: 500;
}

.divider, .select-title {
  /* font-size: 1pt; */
  color: #581C87;
}

.fill-in-the-blanks > div:last-child {
  display: none;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {
  .fill-in-the-blanks {
    line-height: 2.25rem;
  }

  .fitb-label {
    display: inline;
    font-weight: 500;
    font-family: 'Bitter';
  }

  .fitb-note {
    display: inline;
  }

  .fill-in-the-blanks > div:last-child {
    display: inline;
  }

}

/* md	- starts at width 768px	 */
@media (min-width: 768px) { }
/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {
    line-height: 3.25rem;
}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
